import {from, Observable} from 'rxjs';
import {catchError, filter, switchMap, withLatestFrom} from 'rxjs/operators';
import {createAction} from '@reduxjs/toolkit';
import {ApolloQueryResult} from '@apollo/client';
import {StateObservable} from 'redux-observable';

import {QueryGeoInfoByAddressResponseGQL} from '../api/appSyncAPI/types';
import {queryGeoInfoByAddress} from '../api/appSyncAPI/appSyncApi';
import {fetchDefaultAddress, receiveDefaultAddress, receiveGeoInfoByLocationType} from '../slices/location.slice';
import {addAlert} from '../slices/alerts.slice';
import {RootState} from '../store';
import {queryLatestJobAddress} from '../api/concertAPI/concertApi';
import {queryLatestJobAddressResponseGQL} from '../api/concertAPI/types';
import {LOCATION_MAX_CHAR_LIMIT} from '../common/constant';
import {createJobRequestInEpic} from '../utils/requestService';
import {FEATURE_FLAG_SLICE_NAME} from '../slices/constants/slice-constants';

import {getJobCardsByLocationAction} from './getJobs.epic';

export enum LocationActionTypes {
  GET_LOCATION_ADDRESS = 'location/getGeoInfoByAddress',
}

export interface getLocationAddressPayload {
  address: string;
}

export const getLocationByAddressAction = createAction<getLocationAddressPayload>(
  LocationActionTypes.GET_LOCATION_ADDRESS,
);

export const queryGeoInfoByAddressEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(getLocationByAddressAction.match),
    withLatestFrom(state$),
    switchMap(([actions, state]: [any, RootState]) =>
      from(
        queryGeoInfoByAddress(
          {
            address: actions.payload.address.substring(0, LOCATION_MAX_CHAR_LIMIT),
            countries: ['USA'],
          },
          state[FEATURE_FLAG_SLICE_NAME].isReverseProxyEnabled,
        ),
      ).pipe(
        switchMap(({data: {queryGeoInfoByAddress}}: ApolloQueryResult<QueryGeoInfoByAddressResponseGQL>) => {
          const geoInfo = queryGeoInfoByAddress[0];

          if (!geoInfo) throw new Error('geoInfo is not in response');

          const request = createJobRequestInEpic(state, {location: geoInfo});

          return [receiveGeoInfoByLocationType(geoInfo), getJobCardsByLocationAction({request})];
        }),
        catchError(() => {
          const geoInfo = {lat: 0, lng: 0};
          const request = createJobRequestInEpic(state, {location: geoInfo});

          return [
            receiveGeoInfoByLocationType(geoInfo),
            getJobCardsByLocationAction({request}),
            addAlert({alertMessage: 'Unable to retrieve your location'}),
          ];
        }),
      ),
    ),
  );

export const getDefaultAddressEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(fetchDefaultAddress.match),
    withLatestFrom(state$),
    switchMap(([actions]: [any, RootState]) =>
      from(queryLatestJobAddress(actions.payload.employeeId)).pipe(
        switchMap(
          ({
            data: {
              employee: {
                jobInformation: {
                  location: {postalCode},
                },
              },
            },
          }: ApolloQueryResult<queryLatestJobAddressResponseGQL>) => {
            return [
              receiveDefaultAddress({address: postalCode || ''}),
              getLocationByAddressAction({address: postalCode || ''}),
            ];
          },
        ),
        catchError(() => {
          return [
            receiveDefaultAddress({address: ''}),
            getLocationByAddressAction({address: ''}),
            addAlert({alertMessage: "Unable to retrieve your last job's zipcode"}),
          ];
        }),
      ),
    ),
  );
