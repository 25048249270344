// For more info on feature access via PopStar, see
import {useFeatureGate} from '@amzn/showtime';

// https://evergreen.corp.amazon.com/atoz-developer-program/develop/feature_access/popstar/
export const HVH_CAREERS_INGRESS_POINT_FEATURE_ID = 'hvh_career_site_ingress_point';
export const HVH_CAREERS_LOAD_MORE_FEATURE_ID = 'hvh_career_load_more';

export const HVH_CAREERS_JOB_DETAIL_FEATURE_ID = 'hvh_career_job_detail';
export const ATOZ_ALUMNI_SALARIED_JOBS_FEATURE_ID = 'alumni_jobs_salaried_tab';

export const HVH_CAREERS_JOB_DETAIL_MATCH_FEATURE_ID = 'hvh_career_job_detail_match';

export const HVH_CAREERS_REVERSE_PROXY_MIGRATION_FEATURE_ID = 'proxy_migration_hvh_career';

const hasFeature = (features: string[], feature: string) => features.includes(feature);

export const hasHVHCareersFeature = (features: string[]) => hasFeature(features, HVH_CAREERS_INGRESS_POINT_FEATURE_ID);
export const hasHVHCareersLoadMore = (features: string[]) => hasFeature(features, HVH_CAREERS_LOAD_MORE_FEATURE_ID);
export const hasSalariedAlumniJobs = (features: string[]) => hasFeature(features, ATOZ_ALUMNI_SALARIED_JOBS_FEATURE_ID);
export const hasHVHCareersJobDetail = (features: string[]) => hasFeature(features, HVH_CAREERS_JOB_DETAIL_FEATURE_ID);
export const useIsLoadMoreFeatureEnabled = () => useFeatureGate(HVH_CAREERS_LOAD_MORE_FEATURE_ID);
export const useIsJobDetailPageEnabled = () => useFeatureGate(HVH_CAREERS_JOB_DETAIL_FEATURE_ID);
export const useIsAccountMatchPageEnabled = () => useFeatureGate(HVH_CAREERS_JOB_DETAIL_MATCH_FEATURE_ID);
export const useIsReverseProxyEnabled = () => useFeatureGate(HVH_CAREERS_REVERSE_PROXY_MIGRATION_FEATURE_ID);
