import {createSlice} from '@reduxjs/toolkit';

import {FEATURE_FLAG_SLICE_NAME} from './constants/slice-constants';

export interface FeatureFlagState {
  isReverseProxyEnabled: boolean;
}

export const initialFeatureFlagState: FeatureFlagState = {
  isReverseProxyEnabled: false,
};

const {actions, reducer} = createSlice({
  name: FEATURE_FLAG_SLICE_NAME,
  initialState: initialFeatureFlagState,
  reducers: {
    setFeatureFlag(state, {payload: {isReverseProxyEnabled}}) {
      state.isReverseProxyEnabled = isReverseProxyEnabled;
    },
  },
});

export const {setFeatureFlag} = actions;
export default reducer;
