import React from 'react';
import {ConnectedComponent, Provider} from 'react-redux';
import {PlacementProps} from '@amzn/showtime';

import store from '../store';

import FeatureFlagProvider from './FeatureFlagProvider';

const withProviders = <T extends PlacementProps>(
  WrappedComponent: ConnectedComponent<React.ComponentType<any>, any> | React.ComponentType<any>,
): React.FC<T> => {
  const HOCComponent = (props: T) => (
    <Provider store={store}>
      <FeatureFlagProvider>
        <WrappedComponent {...props} />
      </FeatureFlagProvider>
    </Provider>
  );

  HOCComponent.displayname = 'HVHCareersHOC';
  return HOCComponent;
};

export default withProviders;
